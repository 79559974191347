html, body {
  -ms-touch-action: none;
  padding: 0;
  border: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-repeat:  no-repeat;
  background-size: 100% 100%;
  font-family: "Microsoft YaHei" ! important;
  font-style: normal;
}

@z-page: 0; //页面
@z-dlg:3; //对话框
// @z-spinner:4; //菊花
// @z-toast:5; //弱提示

.page {
  width:100%;
  min-height: 100%;
  // background: #000;
}
.dlg {
  position: fixed;
  z-index:@z-dlg;

  margin: auto;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;

  border-radius:2px;
}
.mask {
  position: fixed;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  opacity: 0.3;
  z-index: -1; //kone todo  zindex -1 可能在一些浏览器不兼容
}


.btn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // display: block;
  // width: 2rem;
  // height: 1rem;
  // line-height: 1rem;
  // background: blue;
  // border-radius: .2rem;
  color: #000;
  text-align: center;
  // font-size: 0.36rem;
  cursor:pointer;
}

// 文本按钮，没有边框、底色
.btn-txt {
  border:none;
  background-color: rgba(0,0,0,0);
}


/*elementUI */ 
/*去掉 el-radio 前面的点 */
.el-radio__input{
  display: none!important;
}