


















#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  width:100%;
  height: 100%;
  margin: auto;
  // background: #8204b5;
  position: relative;

}
